import React from "react"
import { navigateTo } from "gatsby-link"
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types"
import config from "../../../utils/siteConfig"
import Button from "../../Atoms/Button/Button"
import registerFormStyles from "./RegisterEventForm.module.scss"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye } from "@fortawesome/free-solid-svg-icons"
const eye = <FontAwesomeIcon icon={faEye} />

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export default class RegisterEventForm extends React.Component {
  constructor(props) {
    super(props)
    debugger
    this.props = props
    this.state = {}
  }

  options = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => (
        <h1 className="heading1">{children}</h1>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <h2 className="heading2">{children}</h2>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <h3 className="heading3">{children}</h3>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <h4 className="heading4">{children}</h4>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => (
        <img src={`https:${node.data.target.fields.file["en-US"].url}`} />
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className="copy">{children}</p>
      ),
    },
    renderMark: {},
    renderText: text =>
      text
        .replace(/\u2028/g, "")
        .split("\n")
        .flatMap((text, i) => [i > 0 && <br />, text]),
  }

  debugger
  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    Promise.all([
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": form.getAttribute("name"),
          ...this.state,
        }),
      }),
      fetch("https://getform.io/f/f8237525-74ff-4231-adc6-7a47da409cd5", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": form.getAttribute("name"),
          ...this.state,
        }),
      }),
    ])
      .then(() => navigateTo("/confirmation"))
      .catch(error => alert(error))
  }

  render() {
    return (
      <div className={registerFormStyles.registerForm}>
        <form
          name={this.props.evHHd}
          method="post"
          // action="https://getform.io/f/f8237525-74ff-4231-adc6-7a47da409cd5"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={this.handleSubmit}
        >
          {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
          <input type="hidden" name="form-name" value={this.props.evHHd} />
          <p hidden>
            <label>
              Don’t fill this out:{" "}
              <input name="bot-field" onChange={this.handleChange} />
            </label>
          </p>

          <div className="block pt-3">
            <label for="name">First name</label>
            <input
              type="text"
              name="name"
              id="name"
              onChange={this.handleChange}
              pattern=".{,50}"
              title="Not more than 50 symbols"
              required
            />
          </div>

          <div className="block pt-3">
            <label for="name">Last name</label>
            <input
              type="text"
              name="name"
              id="name"
              onChange={this.handleChange}
              pattern=".{,50}"
              title="Not more than 50 symbols"
              required
            />
          </div>

          <div className="block pt-3">
            <label for="email">Email address</label>
            <input
              type="email"
              name="email"
              id="email"
              inputMode="email"
              onChange={this.handleChange}
              pattern="^[\w]{1,}[\w.+-]{0,}@[\w-]{2,}([.][a-zA-Z]{2,}|[.][\w-]{2,}[.][a-zA-Z]{2,})$"
              required
              title="Enter correct e-mail format"
            />
          </div>

          <div className="block pt-3">
            <label for="name">Company name</label>
            <input
              type="text"
              name="name"
              id="name"
              onChange={this.handleChange}
              pattern=".{,50}"
              title="Not more than 50 symbols"
              required
            />
          </div>

          <div className="block pt-3">
            <label for="name">Title</label>
            <input
              type="text"
              name="name"
              id="name"
              onChange={this.handleChange}
              pattern=".{,50}"
              title="Not more than 50 symbols"
              required
            />
          </div>

          <div className="block pt-3">
            <label for="companySize">Company size</label>
            <select
              type="text"
              id="companySize"
              name="companySize"
              onChange={this.handleChange}
            >
              <option value="" disabled selected hidden>
                Select...
              </option>
              <option value="1-99">1-99</option>
              <option value="100-999">100-999</option>
              <option value="1000-4999">1000-4999</option>
              <option value="5000+">5000+</option>
            </select>
          </div>

          <div className="row justify-content-center pt-3">
            <button
              className={registerFormStyles.btn}
              type="submit"
              name="submit"
            >
              Register now
            </button>
          </div>
          <div className="row justify-content-center pt-3">
            <p>By registering to attend this webinar you acknowledge that you will
            receive marketing communications from Envoy. You can unsubscribe at
            any time. <a href={config.navigationURL_privacy}>Full Privacy Policy.</a> </p>
          </div>
        </form>
      </div>
    )
  }
}
