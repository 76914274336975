import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../styles/templates/blog.scss'
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import ContactCell from "../components/Organisms/ContactCell/ContactCell"
import RegisterEventForm from "../components/Organisms/RegisterEventForm/RegisterEventForm"
import config from '../utils/siteConfig'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'


import Button from "../components/Atoms/Button/Button"
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types"


const EventTemplate = ({ data, pageContext }) => {
    debugger;

    const page = data.contentfulEvent
    const eventPage = data.allContentfulEvent.edges[0].node

    const events = data.allContentfulEvent.edges

    const allData = []

    const Bold = ({ children }) => <span className="bold">{children}</span>
    //const Text = ({ children }) => <p className="align-center">{children}</p>;


    const options = {
        renderNode: {
            [BLOCKS.HEADING_1]: (node, children) => (
                <h1 className="heading1">{children}</h1>
            ),
            [BLOCKS.HEADING_2]: (node, children) => (
                <h2 className="heading2">{children}</h2>
            ),
            [BLOCKS.HEADING_3]: (node, children) => (
                <h3 className="heading3">{children}</h3>
            ),
            [BLOCKS.HEADING_4]: (node, children) => (
                <h4 className="section10Bold">{children}</h4>
            ),
            [BLOCKS.EMBEDDED_ASSET]: (node, children) => (
                <img src={`https:${node.data.target.fields.file["en-US"].url}`} />
            ),
            [BLOCKS.PARAGRAPH]: (node, children) => (
                <p className="p-medium3">{children}</p>
            ),
            [BLOCKS.UL_LIST]: (node, children) => {
                return (
                    <ul
                        style={{
                            listStyle: 'disc',
                            lineHeight: '35px',
                            marginLeft: '1rem',
                        }}
                    >
                        {children.map((item) => (
                            <li key={item.key}>{item.props.children[0].props.children[0]}</li>
                        ))}
                    </ul>
                );
            },
            //[BLOCKS.UL_LIST]: (node, children) => (
            //    <span className="d-inline-block bulletSpan">{children}</span>
            //),

        },
        renderMark: {
            [MARKS.BOLD]: text => <Bold>{text}</Bold>,
        },
        renderText: (text) =>
            text
                .replace(/\u2028/g, "")
                .split("\n")
                .flatMap((text, i) => [i > 0 && <br />, text])
    }

    const {
        metaData,
        id,
        evCHd,
        evCRtxt,
        evCRtxt2,
        evCRtxt3,
        evFHd,
        evHDate,
        evFTxt,
        evHHd,
        evHRtxt,
        evHType,
        evMHd,
        evMModerator,
        evSSpeaker,
        evSHd,

    } = page


    const resource = page

    return (
        <Layout
            IsHeaderImageDark={false}
            navColour={"white"}>

            <head>
                <title>{metaData.title}</title>
                <meta charset="utf-8" />
                <meta name="description" content={metaData.description} />
            </head>

            <section>
                <div className="sectionFit-container bg-white">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="row pt-2">
                                <h1 className="h1-largeMedium4 h1-darkBlue"> {evHHd} </h1>
                            </div>
                            <div className="row align-items-center">
                                <h4 className="p-regular9 h1-purple "> {evHType} </h4>
                                <h4 className="p-regular3 text-darkgray7"> {evHDate} </h4>
                            </div>
                            <div className="row pt-4">
                                <p className="h2-large text-darkgray">  {documentToReactComponents(evHRtxt.json, options)} </p>
                            </div>
                        </div>
                        <div className="col-lg-6 pl-5">
                            <RegisterEventForm {...page} />
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-lightgray">
                <div className="sectionFit-container bg-lightgray">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="row pt-2">
                                <h3 className="h3-huge2 h1-darkBlue"> {evCHd} </h3>
                            </div>
                            <div className="row pt-2">
                                <p className="p-indexCircle text-white"> 1 </p>
                                <p className="p-regular10 text-darkgray">  {documentToReactComponents(evCRtxt.json, options)} </p>
                            </div>
                            <div className="row pt-2">
                                <p className="p-indexCircle text-white"> 2 </p>
                                <p className="p-regular10 text-darkgray">  {documentToReactComponents(evCRtxt2.json, options)} </p>
                            </div>
                            <div className="row pt-2">
                                <p className="p-indexCircle text-white"> 3 </p>
                                <p className="p-regular10 text-darkgray">  {documentToReactComponents(evCRtxt3.json, options)} </p>
                            </div>
                        </div>
                        <div className="col-lg-6 pl-5">
                            <div class="row pt-2 pb-2">
                                <h3 className="h3-large2 h1-darkBlue">{evSHd}</h3>
                            </div>
                            {evSSpeaker.map(evSSpeaker => (

                                <div className="row align-item-center pt-3">
                                    <div className="col-lg-3 pt-3">
                                        <img src={evSSpeaker.profilePicture.file.url} className="img-avatart" alt={evSSpeaker.profilePicture.title} />

                                    </div>
                                    <div className="col-lg-9 pt-3">
                                        <div className="row pt-3">
                                            <h4 className="p-regular11 h1-darkBlue"> {evSSpeaker.name}</h4>
                                        </div>
                                        <div className="row pt-1">
                                            <h4 className="p-regular11 text-lightgray"> {evSSpeaker.jobTitle}</h4>
                                        </div>
                                    </div>
                                </div>
                            ))}

                            <div class="row pt-5 pb-2">
                                <h3 className="h3-large2 h1-darkBlue">{evMHd}</h3>
                            </div>
                            {evMModerator.map(evMModerator => (
                                <div className="row align-item-center pt-3">
                                    <div className="col-lg-3 pt-3">
                                        <img src={evMModerator.profilePicture.file.url} className="img-avatart" alt={evMModerator.profilePicture.title} />
                                    </div>
                                    <div className="col-lg-9 pt-3">
                                        <div className="row pt-3">
                                            <h4 className="p-regular11 h1-darkBlue"> {evMModerator.name}</h4>
                                        </div>
                                        <div className="row pt-1">
                                            <h4 className="p-regular11 text-lightgray"> {evMModerator.jobTitle}</h4>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="row text-white pt-5 align-items-center">
                        <div className="col-lg-6 pl-0 pr-5 pt-5">
                            <h1 className="align-top h1-medium2 h1-darkBlue">
                                {evFHd}
                            </h1>
                            <p className="p-regular text-darkgray3 pt-1">
                                {evFTxt}
                            </p>
                        </div>
                        <div className="col-lg-6 pl-5 pr-0 pt-5">
                            <div className="subInput pt-4">
                                <ContactCell {...page} />
                            </div>
                            <h2 className="h2-medium text-darkgray pt-3">
                            </h2>
                        </div>
                    </div>
                </div>
            </section>

        </Layout >
    )
}

export const query = graphql`
query($slug: String!, $locale: String!) {
    contentfulEvent(
        metaData: {
        slug: { eq: $slug }
      },
        node_locale: { eq: $locale }) {
            evCHd
            evCRtxt {
              json
            }
           evCRtxt2 {
              json
            }
           evCRtxt3 {
             json
            }
           evFHd
           evHDate
           evFTxt
            evHHd
            evHRtxt {
             json
           }
            evHType
           evMHd
            evSHd
            evMModerator {
                profilePicture {
                  file {
                    url
                    fileName
                  }
                  description
                  title
                }
                name
                jobTitle
              }
              evSSpeaker {
                profilePicture {
                  title
                  file {
                    url
                    fileName
                  }
                }
                name
                jobTitle
              }
           metaData {
             slug
             title
             description
            }
           id 
      }
    
      allContentfulEvent(
        filter: {
        node_locale: { eq: $locale }
      }) {
        edges {
          node {
            evCHd
        evCRtxt {
          json
        }
       evCRtxt2 {
          json
        }
       evCRtxt3 {
         json
        }
       evFHd
       evHDate
       evFTxt
        evHHd
        evHRtxt {
         json
       }
        evHType
       evMHd
       evMModerator {
        profilePicture {
          file {
            url
            fileName
          }
          description
          title
        }
        name
        jobTitle
      }
      evSSpeaker {
        profilePicture {
          title
          file {
            url
            fileName
          }
        }
        name
        jobTitle
      }
        evSHd
       metaData {
         slug
         title
         description
        }
       id
    
          }
        }
      }

}
`

export default EventTemplate
